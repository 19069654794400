import { FC, useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Call as TwilioCall } from '@twilio/voice-sdk'
import { useVoip } from '../../../hooks/voip'
import Notch from './Notch'
import HandleInboundVoipCallWindow from './HandleInboundVoipCallWindow'
import CampaignDetailsWindow from './CampaignDetailsWindow'
import VoipInboundCallDetailsWindow from './VoipInboundCallDetailsWindow'
import VoipOutboundCallDetailsWindow from './VoipOutboundCallDetailsWindow'

const State = TwilioCall.State

const VoipWindow: FC = () => {
  const { campaign, inboundCall, inboundCallAccepted, outboundCall, userLead } = useVoip()

  const [showCampaignDetailsWindow, setShowCampaignDetailsWindow] = useState(false)
  const toggleShowCampaignDetailsWindow = () => {
    setShowCampaignDetailsWindow(!showCampaignDetailsWindow)
  }

  // Close campaign details window when there is an inbound call
  useEffect(() => {
    if (inboundCall?.status() !== State.Closed) {
      setShowCampaignDetailsWindow(false)
    }
  }, [inboundCall])

  const showHandleInboundCallWindow = inboundCall?.status() === State.Pending
  const showCallDetailsWindow =
    inboundCall?.status() === State.Open ||
    (inboundCallAccepted && inboundCall?.status() === State.Closed)
  const showVoipOutbundCallDetailsWindow = userLead

  const windowOpen =
    showCampaignDetailsWindow ||
    showHandleInboundCallWindow ||
    showCallDetailsWindow ||
    showVoipOutbundCallDetailsWindow

  return (
    <>
      <div className="z-10 w-[755px] ">
        <Notch
          campaign={campaign}
          showCampaignDetailsWindow={showCampaignDetailsWindow}
          toggleShowCampaignDetailsWindow={toggleShowCampaignDetailsWindow}
          inboundCall={inboundCall}
          outboundCall={outboundCall}
          userLead={userLead}
        />
        <AnimatePresence>
          {windowOpen && (
            <motion.div
              key="box"
              layout
              initial={{ y: 0, scaleY: 1, opacity: 1, height: 0 }}
              animate={{ y: 0, scaleY: 1, opacity: 1, height: 'auto' }}
              exit={{ y: 0, scaleY: 1, opacity: 1, height: 0 }}
              transition={{
                duration: 0.2,
                layout: { ease: 'linear' },
              }}
            >
              <div className="mb-[18px] h-[470px] w-[755px] bg-white">
                {campaign && (
                  <CampaignDetailsWindow
                    show={showCampaignDetailsWindow}
                    setShow={setShowCampaignDetailsWindow}
                    campaign={campaign}
                  />
                )}
                {showHandleInboundCallWindow && (
                  <HandleInboundVoipCallWindow inboundCall={inboundCall!} campaign={campaign} />
                )}
                {showCallDetailsWindow && (
                  <VoipInboundCallDetailsWindow inboundCall={inboundCall!} />
                )}
                {showVoipOutbundCallDetailsWindow && (
                  <VoipOutboundCallDetailsWindow outboundCall={outboundCall} />
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {windowOpen && (
          <motion.div
            style={{ position: 'relative', zIndex: 2 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.4 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div
              className="fixed inset-0 bg-[--colors-modal--backdrop]"
              id="pulsar-modal--backdrop"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default VoipWindow

import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import backend from '../../backend'
import { UserLead } from '../../types/user_lead'

const fetchLead = async ({ queryKey }: QueryFunctionContext): Promise<UserLead> => {
  const [phoneNumber] = queryKey
  const { body } = await backend.get(`/leads/phone/${phoneNumber}`)
  return body
}

export const useFetchUserLeadByPhone = ({ phoneNumber }: { phoneNumber: string }) => {
  return useQuery<UserLead>({
    queryKey: [phoneNumber],
    queryFn: fetchLead,
    enabled: !!phoneNumber,
  })
}

import { find } from 'lodash'
import { isPromoValid } from './promos'
import { Allocation } from '../types/teams'
interface PricingPerLead {
  maxPrice: number
  minPrice: number | null
  statesPricingDetails?: any[] | null
}

export const formatCurrency = (cents: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100)
}

export const formatUSD = (cents: number) => `$${(cents / 100).toFixed(2)}`

export const formatSubscription = (cents: number) => `$${cents / 100}`

export const dollarsFrom = (cents: number) => (cents / 100).toFixed()

export const prettyDollars = (dollars: number) =>
  `$${dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`

export const getCostPerLead = ({ campaign, userId }: { campaign: any; userId?: string }) => {
  let pricing: {
    maxPrice: number | null
    minPrice: number | null
    statesPricingDetails?: any[] | null
  } = {
    maxPrice: null, // maxPrice will either be the most expensive lead state price or a standard national product price
    minPrice: null, // minPrice is only used when there are a range of prices and is the lowest price
    statesPricingDetails: null,
  }

  const campaignPricing = find(campaign.product?.pricing, { state: null }) || {}

  if (campaignPricing.price) {
    pricing.maxPrice = campaignPricing.price
  }

  let adjustedDiscount = 1

  if (campaign.states && campaign.states.length > 0) {
    const states = campaign.states.map((state: any) => state.state)
    const statePricing = getStatesPricing({
      states,
      campaignPricing: campaign.product.pricing,
      nationalPrice: campaignPricing.price,
    })
    if (statePricing) {
      pricing = statePricing
    }
  }

  if (campaign.targeted_states && campaign.targeted_states.length > 0) {
    const states = campaign.targeted_states.map(
      (targeted_state: any) => targeted_state?.state || targeted_state
    )
    const statePricing = getStatesPricing({
      states,
      campaignPricing: campaign.product.pricing,
      nationalPrice: campaignPricing.price,
    })

    if (statePricing) {
      pricing = statePricing
    }
  }

  if (campaign.counties && campaign.counties.length > 0) {
    const states = campaign.counties.map((county: any) => county.state.abbreviation)
    const statePricing = getStatesPricing({
      states,
      campaignPricing: campaign.product.pricing,
      nationalPrice: campaignPricing.price,
    })
    if (statePricing) {
      pricing = statePricing
    }
  }

  if (!pricing.maxPrice) {
    throw new Error(`Unable to get cost per lead for campaign id ${campaign.id}`)
  }

  const { promotion } = campaign

  if (promotion && isPromoValid({ promotion }) && !promotion?.allocation_group_id) {
    if (promotion.fixed_discount) {
      pricing.maxPrice -= promotion.fixed_discount
      if (pricing.minPrice) {
        pricing.minPrice -= promotion.fixed_discount
      }
    } else {
      adjustedDiscount -= promotion.discount
    }
  }

  if (!pricing.maxPrice) {
    throw new Error(`Missing pricing for product ${campaign.product?.id}`)
  }

  // For pages querying for specific promotion by id
  if (promotion?.allocation_group_id) {
    return getPricingForPromotion({
      pricing: pricing as PricingPerLead,
      promotion,
      adjustedDiscount,
    })
  }

  let { allocation_group, user_allocation } = campaign

  // Filter to specific user's allocation for campaigns list
  if (user_allocation?.length > 1) {
    user_allocation = user_allocation.filter(
      (allocation: Allocation) => allocation.to_user_id === userId
    )
  }

  if (allocation_group && user_allocation) {
    const amountRemaining = user_allocation[0]?.amount_remaining
    const discountPercent = allocation_group?.discount_percent

    pricing.maxPrice = getDynamicDiscount({
      price: pricing.maxPrice,
      amountRemaining,
      discountPercent,
    })
    if (pricing.minPrice !== null) {
      pricing.minPrice = getDynamicDiscount({
        price: pricing.minPrice,
        amountRemaining,
        discountPercent,
      })
    }

    if (pricing.statesPricingDetails) {
      pricing.statesPricingDetails = pricing.statesPricingDetails.map((state) => {
        return {
          ...state,
          price: getDynamicDiscount({ price: state.price, amountRemaining, discountPercent }),
        }
      })
    }
  }

  return {
    maxPrice: pricing.maxPrice * adjustedDiscount,
    minPrice: pricing.minPrice !== null ? pricing.minPrice * adjustedDiscount : null,
    statesPricingDetails: pricing.statesPricingDetails || null,
  }
}

export const getFormattedCostPerLead = ({
  campaign,
  userId,
}: {
  campaign: any
  userId?: string
}): string => {
  const { maxPrice, minPrice } = getCostPerLead({ campaign, userId })

  if (minPrice !== null && maxPrice !== null) {
    return `${formatUSD(minPrice)} - ${formatUSD(maxPrice)}`
  } else if (maxPrice !== null) {
    return formatUSD(maxPrice)
  } else {
    throw new Error('Unable to format cost per lead.')
  }
}

const getStatesPricing = ({
  states,
  campaignPricing,
  nationalPrice,
}: {
  states: string[]
  campaignPricing: any[]
  nationalPrice?: number
}): PricingPerLead | null => {
  let useNationalPricing = false

  const statesPricing = states.map((state) => {
    const statePricing = campaignPricing.find((pricing: any) => pricing.state === state)
    if (!statePricing) {
      // this means that there is a state selected without state specific pricing and the national price is highest
      useNationalPricing = true
      return {
        state,
        price: nationalPrice,
      }
    } else {
      return statePricing
    }
  })

  if (statesPricing.length) {
    const sortedPricing = statesPricing.sort((a, b) => b.price - a.price)
    let maxPrice = sortedPricing[0].price
    let minPrice = sortedPricing[sortedPricing.length - 1].price

    if (nationalPrice && useNationalPricing) {
      if (nationalPrice > maxPrice) {
        maxPrice = nationalPrice
      }
      if (nationalPrice < minPrice) {
        minPrice = nationalPrice
      }
    }

    return {
      maxPrice,
      minPrice: minPrice && maxPrice !== minPrice ? minPrice : null,
      statesPricingDetails: statesPricing,
    }
  }

  return null
}

const applyDiscount = ({
  price,
  promotion,
  adjustedDiscount,
}: {
  price: number
  promotion: { amount_remaining: number; discount_percent: number }
  adjustedDiscount: number
}): number => {
  if (promotion.amount_remaining < price * promotion.discount_percent) {
    // Can only discount up to the amount remaining for an allocation
    return price - promotion.amount_remaining
  } else {
    adjustedDiscount -= promotion.discount_percent
    return price * adjustedDiscount
  }
}

const getPricingForPromotion = ({
  pricing,
  promotion,
  adjustedDiscount,
}: {
  pricing: PricingPerLead
  promotion: any
  adjustedDiscount: number
}) => {
  let statesPricingDetails = null

  if (pricing.statesPricingDetails) {
    statesPricingDetails = pricing.statesPricingDetails.map((state) => {
      return {
        ...state,
        price: applyDiscount({ price: state.price, promotion, adjustedDiscount }),
      }
    })
  }
  return {
    maxPrice: applyDiscount({ price: pricing.maxPrice, promotion, adjustedDiscount }),
    minPrice: pricing.minPrice
      ? applyDiscount({ price: pricing.minPrice, promotion, adjustedDiscount })
      : null,
    statesPricingDetails,
  }
}

const getDynamicDiscount = ({
  price,
  amountRemaining,
  discountPercent,
}: {
  price: number
  amountRemaining: number | undefined
  discountPercent: number | undefined
}) => {
  if (amountRemaining && discountPercent) {
    if (amountRemaining < price * discountPercent) {
      return price - amountRemaining
    } else {
      return price * discountPercent
    }
  }
  return price
}

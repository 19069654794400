import { useEffect } from 'react'
import { Call as TwilioCall } from '@twilio/voice-sdk'
import { parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import { useStopwatch } from 'react-timer-hook'
import { Phone, Mic, MicOff } from 'lucide-react'
import cn from 'classnames'
import { Icon, Text } from '@leadrilla/pulsar'
import { LeadFeed } from '../../../types/lead_feed'
import { UserLead } from '../../../types/user_lead'
import { useVoip, useVoipActions } from '../../../hooks/voip'
import { useCampaigns } from '../../../hooks/campaigns'
import CallSpinnerIcon from '../../../components/CallSpinnerIcon/call_spinner_icon'

const State = TwilioCall.State

const Notch = ({
  campaign,
  showCampaignDetailsWindow,
  toggleShowCampaignDetailsWindow,
  inboundCall,
  outboundCall,
  userLead,
}: {
  campaign?: LeadFeed
  showCampaignDetailsWindow: boolean
  toggleShowCampaignDetailsWindow: () => void
  inboundCall?: TwilioCall
  outboundCall?: TwilioCall
  userLead?: UserLead
}) => {
  const pending =
    inboundCall?.status() === State.Pending || outboundCall?.status() === State.Pending
  const open =
    inboundCall?.status() === State.Open ||
    outboundCall?.status() === State.Open ||
    outboundCall?.status() === State.Connecting
  const closed = inboundCall?.status() === State.Closed || outboundCall?.status() === State.Closed
  return (
    <div
      className={cn('flex h-[82px] items-center rounded-t-[16px] px-[32px]', {
        'bg-[--colors-action]': !open,
        'bg-[--colors-positive]': open,
      })}
    >
      {((campaign && !inboundCall) || (campaign && pending)) && (
        <CampaignNotch
          campaign={campaign}
          showCampaignDetailsWindow={showCampaignDetailsWindow}
          toggleShowCampaignDetailsWindow={toggleShowCampaignDetailsWindow}
        />
      )}
      {inboundCall && (open || closed) && (
        <InboundCallNotch inboundCall={inboundCall} campaign={campaign} />
      )}
      {outboundCall && userLead && (
        <OutboundCallNotch outboundCall={outboundCall} userLead={userLead} />
      )}
    </div>
  )
}

export default Notch

const CampaignNotch = ({
  campaign,
  showCampaignDetailsWindow,
  toggleShowCampaignDetailsWindow,
}: {
  campaign: LeadFeed
  showCampaignDetailsWindow: boolean
  toggleShowCampaignDetailsWindow: () => void
}) => {
  const { devMode } = useVoip()
  const { triggerDevModeInboundCall, unregisterCallCampaign } = useVoipActions()
  const { pauseCampaignWithReason } = useCampaigns()

  return (
    <>
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-6">
          <CallSpinnerIcon spinner />
          <div className="flex flex-col items-center space-y-4">
            <Text tone="ghost" size="small" truncate>
              Waiting for a call...
            </Text>
            <Text tone="ghost" size="big" weight="stronger" truncate>
              {campaign.product?.name}
            </Text>
          </div>
        </div>
        <div className="flex items-center gap-10">
          {devMode && (
            <div className="cursor-pointer">
              <Icon name="phone" tone="ghost" onClick={triggerDevModeInboundCall} />
            </div>
          )}
          <button
            onClick={() => {
              pauseCampaignWithReason(campaign.id, unregisterCallCampaign)
            }}
            className="rounded-md border-2 border-solid border-white px-[10px] py-[6px]"
          >
            <Text size="small" weight="stronger" tone="ghost">
              Pause Campaign
            </Text>
          </button>
          <div className="cursor-pointer" onClick={() => toggleShowCampaignDetailsWindow()}>
            <Icon name="arrow" rotate={showCampaignDetailsWindow ? 0 : 180} size="l" tone="ghost" />
          </div>
        </div>
      </div>
    </>
  )
}

const InboundCallNotch = ({
  inboundCall,
  campaign,
}: {
  inboundCall: TwilioCall
  campaign?: LeadFeed
}) => {
  const open = inboundCall.status() === State.Open
  const muted = inboundCall.isMuted()
  return (
    <div className="flex w-full justify-between">
      <div className="flex items-center gap-6">
        <CallSpinnerIcon />
        <div className="flex flex-col space-y-4">
          <Text tone="ghost" size="small" truncate>
            {open ? 'Active Call' : 'Call Ended'}
            {campaign && ` (${campaign.product?.name})`}
          </Text>
          <div className="flex gap-4">
            <Text tone="ghost" size="big" weight="stronger" truncate>
              {parsePhoneNumberFromString(inboundCall.parameters.From)?.formatNational()}
            </Text>
            <CallDurationTimer inboundCall={inboundCall} />
          </div>
        </div>
      </div>
      {open && (
        <div className="flex items-center gap-12">
          <div className="cursor-pointer" onClick={() => inboundCall.mute(!muted)}>
            {muted ? <Mic color="white" /> : <MicOff color="white" />}
          </div>
          <div className="cursor-pointer" onClick={() => inboundCall.disconnect()}>
            <Phone color="white" style={{ transform: 'rotate(135deg)' }} />
          </div>
        </div>
      )}
    </div>
  )
}

const OutboundCallNotch = ({
  outboundCall,
  userLead,
}: {
  outboundCall: TwilioCall
  userLead?: UserLead
}) => {
  const status = outboundCall.status()
  const open = status === State.Open || status === State.Connecting
  const agentTwilioNumber = outboundCall.customParameters.get('From')
  const muted = outboundCall.isMuted()

  return (
    <div className="flex w-full justify-between">
      <div className="flex items-center gap-6">
        <CallSpinnerIcon />
        <div className="flex flex-col space-y-2">
          <Text tone="ghost" size="small" truncate>
            {open ? 'Active Call ' : 'Call Ended '}
            {parsePhoneNumber(outboundCall.customParameters.get('To') || '')?.formatNational()}
          </Text>
          <div className="flex gap-4">
            <Text tone="ghost" size="bigger" weight="stronger">
              {userLead?.first_name} {userLead?.last_name}
            </Text>
          </div>
        </div>
      </div>
      {open && (
        <>
          <div className="flex gap-12">
            <div className="flex select-none items-center gap-8">
              <div className="cursor-pointer" onClick={() => outboundCall.mute(!muted)}>
                {muted ? <Mic color="white" /> : <MicOff color="white" />}
              </div>
              <div className="cursor-pointer" onClick={() => outboundCall.disconnect()}>
                <Phone color="white" style={{ transform: 'rotate(135deg)' }} />
              </div>
            </div>
            <div className="flex flex-col justify-center space-y-2">
              <Text tone="ghost">Calling from</Text>
              <Text tone="ghost" size="big" weight="stronger" truncate>
                {parsePhoneNumberFromString(agentTwilioNumber || '')?.formatNational()}
              </Text>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const CallDurationTimer = ({
  inboundCall,
  outboundCall,
}: {
  inboundCall?: TwilioCall
  outboundCall?: TwilioCall
}) => {
  const { hours, minutes, seconds, pause } = useStopwatch({ autoStart: true })

  const open = inboundCall?.status() === State.Open || outboundCall?.status() === State.Open

  useEffect(() => {
    if (!open) pause()
  }, [open])

  return (
    <Text tone="ghost" size="big" weight="stronger" truncate>
      {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:
      {seconds.toString().padStart(2, '0')}
    </Text>
  )
}

// @ts-nocheck FIXME
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { DateTime } from 'luxon'
import { Box, Drawer, Dialog, Stack, Text, PrimaryButton } from '@leadrilla/pulsar'

import { UserContext } from '../../components/AuthProvider/auth_provider'
import backend from '../../backend'
import { OK } from '../../constants/error_codes'
import { analyticsEvent } from '../../helpers/heap'
import MaintenanceMode from '../../components/MaintenanceMode/maintenance_mode'
import Header from '../../components/Header/header'
import MobileMenu from '../../components/MobileMenu/mobile_menu'
import Sidebar from '../../components/Sidebar/sidebar'
import ErrorBoundary from '../../components/ErrorBoundary/error_boundary'
import CallBar from '../../components/PresenceDialog/PresenceDialog'
// import VoiceUpdateInfoDialog from '../Modals/VoiceUpdateInfoDialog'
import { CallCampaignProvider } from '../CallBar/CallContext'
import { CampaignsProvider } from '../../hooks/campaigns'
import { PusherProvider as OldPusherProvider } from '../../hooks/pusher_old'
import { useTenantConfig, isLeadrilla } from '../../hooks/TenantConfig'
import { VoipProvider } from '../../hooks/voip'
import { usePusherEvent } from '../../hooks/pusher'
import SubscriptionDialog from '../../components/SubscriptionDialog/subscription_dialog'
import MigrationModal from '../../components/MigrationModal/migration_modal'
import AlertUserToRefreshModal from '../../components/AlertUserToRefreshModal/alert_user_to_refresh_modal'

function Dashboard(props) {
  const { user, setUser, removeUser } = useContext(UserContext)
  const impersonating = user.impersonator && user.impersonator.email
  const tenantConfig = useTenantConfig()
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false)
  const [showMigrationModal, setShowMigrationModal] = useState(true)

  const [refreshModalIsOpen, setRefreshModalIsOpen] = useState(false)

  usePusherEvent({
    channel: 'notify_user',
    event: 'new_version_soft_refresh',
    onEvent: () => setRefreshModalIsOpen(true),
  })

  usePusherEvent({
    channel: 'notify_user',
    event: 'new_version_hard_refresh',
    onEvent: () => window.location.reload(),
  })

  const subscriptionExpired = (date) => {
    if (!date) return false

    const currentDate = DateTime.local()
    const expiresAt = DateTime.fromISO(date)

    return currentDate > expiresAt
  }

  const subscriptionIsActive = (user) => {
    return user.subscription?.activated_at && !subscriptionExpired(user.subscription?.expires_at)
  }

  const cancelImpersonation = async () => {
    const {
      status,
      body: { jwt, user: u },
    } = await backend.get('/auth/cancel-impersonation')

    if (status === OK) {
      setUser(u, jwt)
      sessionStorage.clear()
      props.history.push(`/dash/users/${user.id}`)
    }
  }

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false)
  // const [showCallDialog, setShowCallDialog] = useState(false)
  //eslint-disable-next-line
  const [showVoiceUpdate, setShowVoiceUpdate] = useState(false)

  useEffect(async () => {
    const welcome = localStorage.getItem('welcome')
    if (welcome && !isLeadrilla()) {
      setShowWelcomeDialog(true)
    }

    if (
      tenantConfig.subscriptions?.enabled &&
      user.type !== 'admin' &&
      !subscriptionIsActive(user) &&
      // So we can show spinner while we figure out if subscription was successful
      window.location.pathname !== '/dash/create-subscriptions-stripe-3ds-response'
    ) {
      setShowSubscriptionDialog(true)
    }

    const hideVoiceDialog = localStorage.getItem('hideVoiceUpdate')
    if (!hideVoiceDialog) {
      // setShowVoiceUpdate(true)
    }
  }, [])

  const welcomeDialogCleanup = () => {
    localStorage.removeItem('welcome')
    setShowWelcomeDialog(false)
  }

  const openMobileMenu = () => {
    setShowMobileMenu(true)
  }

  const closeMobileMenu = () => {
    setShowMobileMenu(false)
  }

  const logOut = () => {
    analyticsEvent('User Logged Out')
    removeUser()
    window.location = '/'
  }

  const pageWrapperRef = useRef()

  const headerHeight = '72px'

  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    return <MaintenanceMode />
  }

  return (
    <CallCampaignProvider>
      <OldPusherProvider>
        <CampaignsProvider>
          <VoipProvider>
            <Box overflow={['scrollY', 'scrollY', 'hidden']}>
              <Drawer
                inset="l"
                open={showMobileMenu}
                onClose={closeMobileMenu}
                defaultClose={false}
                width="full"
                position="left"
              >
                <MobileMenu
                  user={user}
                  close={closeMobileMenu}
                  logOut={logOut}
                  impersonating={impersonating}
                  cancelImpersonation={cancelImpersonation}
                />
              </Drawer>

              <Header
                openMobileMenu={openMobileMenu}
                height={headerHeight}
                user={user}
                impersonating={impersonating}
                cancelImpersonation={cancelImpersonation}
              />

              {tenantConfig.tenant === 'trking' && (
                <MigrationModal
                  open={showMigrationModal}
                  setShowMigrationModal={setShowMigrationModal}
                />
              )}

              {tenantConfig.tenant === 'trking' && (
                <MigrationModal
                  open={showMigrationModal}
                  setShowMigrationModal={setShowMigrationModal}
                  tenant={tenantConfig.tenant}
                />
              )}

              <Box
                height={`calc(100vh - ${headerHeight})`}
                background={[
                  'var(--colors-foreground)',
                  'var(--colors-foreground)',
                  'var(--card--background)',
                ]}
              >
                <div className="flex">
                  <div className="hidden lg:block">
                    <Sidebar logOut={logOut} headerHeight={headerHeight} />
                  </div>
                  <div className="w-full">
                    <StyledPageWrapper ref={pageWrapperRef} headerHeight={headerHeight}>
                      <ErrorBoundary level="page">{props.children}</ErrorBoundary>
                    </StyledPageWrapper>
                  </div>
                </div>
              </Box>
              {showWelcomeDialog && (
                <Dialog
                  maxWidth={['100%', '100%', '1200px']}
                  inset={['l', 'xxl']}
                  display={['block', 'flex']}
                  flexDirection={'row'}
                  flexGrow={['2']}
                  radius="s"
                  marginX={['l', 'none']}
                  open={showWelcomeDialog}
                  onClose={welcomeDialogCleanup}
                >
                  <Box maxWidth={['300px', '600px']}>
                    <Stack space="l">
                      <StyledWelcomeText size="big" weight="fat">
                        Welcome to {tenantConfig.name}!
                      </StyledWelcomeText>

                      <Text size="big" weight="stronger" component="p" pLineHeight="1.5">
                        Take control of your lead and call flows.
                      </Text>

                      <Text size="big" component="p" pLineHeight="1.5">
                        {tenantConfig.welcome_message ||
                          `Grow your sales pipeline with a Campaign! Campaigns offer real-time, exclusive
                        leads as well as calls directly to your phone from interested consumers.
                        Purchase discounted aged leads from our nationwide Marketplace. Use our CRM
                        tools to stay on top of your leads. Our platform is easy to use and ready to
                        help you succeed.`}
                      </Text>

                      <Box marginTop="xs">
                        <PrimaryButton
                          disabled={false}
                          onClick={() => {
                            welcomeDialogCleanup()
                          }}
                          size="bigger"
                        >
                          Continue to the Platform
                        </PrimaryButton>
                      </Box>
                    </Stack>
                  </Box>
                </Dialog>
              )}

              {/* showVoiceUpdate && <VoiceUpdateInfoDialog /> */}
              <SubscriptionDialog
                open={showSubscriptionDialog}
                setOpen={setShowSubscriptionDialog}
              />
              <AlertUserToRefreshModal
                refreshModalIsOpen={refreshModalIsOpen}
                setRefreshModalIsOpen={setRefreshModalIsOpen}
              />
              <CallBar history={props.history} />
            </Box>
          </VoipProvider>
        </CampaignsProvider>
      </OldPusherProvider>
    </CallCampaignProvider>
  )
}

const StyledPageWrapper = styled.div`
  width: 100%;
  border-top: 1px solid transparent;
  overflow-y: scroll;
  padding-top: var(--space--m);

  & > :last-child {
    padding-bottom: 130px;
  }

  @media (max-width: 960px) {
    height: 100%;
    min-height: calc(100vh - ${(p) => p.headerHeight});
    overflow-y: visible;
  }

  @media (min-width: 960px) {
    background: var(--colors-foreground);
    border-top-left-radius: var(--space--xl);
    height: ${(p) => `calc(100vh - ${p.headerHeight});`}
    padding: var(--space--xl);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  }
`

export default styled(Dashboard)`
  background-color: var(--card--foreground);
  min-height: 100%;
  height: 100%;
  width: 100%;
  position: fixed;

  @media (min-width: 960px) {
    background-color: var(--card--background);
  }
`
const StyledWelcomeText = styled('Text')`
  font-size: 24px;
  font-weight: 800;
  @media (min-width: 600px) {
    font-size: 36px;
    font-weight: 800;
  }
`
